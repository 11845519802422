import React from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { saveAs } from 'file-saver';
import 'bootstrap/dist/css/bootstrap.min.css';

const initialXMLData = {
  id: "7268108",
  issueDate: "2024-08-06",
  issueTime: "15:00:32",
  documentType: "Contenedor de Factura Electrónica",
  sender: {
    name: "ESE HOSPITAL SANTA MONICA",
    id: "891411663",
    address: {
      city: "Dos Quebradas",
      country: "COLOMBIA"
    }
  },
  receiver: {
    name: "ADMINISTRADORA DE LOS RECURSOS DEL SISTEMA GENERAL DE SEGURIDAD SOCIAL EN SALUD",
    id: "901037916",
    address: {
      city: "Agua de Dios",
      country: "COLOMBIA"
    }
  },
  totalAmount: "5967450.00",
  currency: "COP",
  invoiceLines: [
    {
      id: "1",
      description: "Q122237106 PLACA BLOQ TIBIA DISTAL MEDIAL X 6H IZQ",
      quantity: "1",
      unitPrice: "2534040.00"
    },
    {
      id: "2",
      description: "Q122247006 PLACA BLOQ TERCIO DE CANA TIT X 6H",
      quantity: "1",
      unitPrice: "1235330.00"
    }
  ]
};

const XMLFormSchema = Yup.object().shape({
  id: Yup.string().required('Requerido'),
  issueDate: Yup.string().required('Requerido'),
  issueTime: Yup.string().required('Requerido'),
  documentType: Yup.string().required('Requerido'),
  sender: Yup.object({
    name: Yup.string().required('Requerido'),
    id: Yup.string().required('Requerido'),
    address: Yup.object({
      city: Yup.string().required('Requerido'),
      country: Yup.string().required('Requerido')
    })
  }),
  receiver: Yup.object({
    name: Yup.string().required('Requerido'),
    id: Yup.string().required('Requerido'),
    address: Yup.object({
      city: Yup.string().required('Requerido'),
      country: Yup.string().required('Requerido')
    })
  }),
  totalAmount: Yup.string().required('Requerido'),
  currency: Yup.string().required('Requerido'),
  invoiceLines: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required('Requerido'),
      description: Yup.string().required('Requerido'),
      quantity: Yup.string().required('Requerido'),
      unitPrice: Yup.string().required('Requerido')
    })
  )
});

export default function XMLForm() {
  const [formData, setFormData] = React.useState({
    id: '',
    issueDate: '',
    issueTime: '',
    documentType: '',
    sender: {
      name: '',
      id: '',
      address: {
        city: '',
        country: ''
      }
    },
    receiver: {
      name: '',
      id: '',
      address: {
        city: '',
        country: ''
      }
    },
    totalAmount: '',
    currency: '',
    invoiceLines: [
      { id: '', description: '', quantity: '', unitPrice: '' }
    ]
  });

  const loadXMLData = () => {
    setFormData(initialXMLData);
  };

  const generateXML = (values) => {
    const xmlContent = `
      <?xml version="1.0" encoding="UTF-8"?>
      <Document>
        <ID>${values.id}</ID>
        <IssueDate>${values.issueDate}</IssueDate>
        <IssueTime>${values.issueTime}</IssueTime>
        <DocumentType>${values.documentType}</DocumentType>
        <Sender>
          <Name>${values.sender.name}</Name>
          <ID>${values.sender.id}</ID>
          <Address>
            <City>${values.sender.address.city}</City>
            <Country>${values.sender.address.country}</Country>
          </Address>
        </Sender>
        <Receiver>
          <Name>${values.receiver.name}</Name>
          <ID>${values.receiver.id}</ID>
          <Address>
            <City>${values.receiver.address.city}</City>
            <Country>${values.receiver.address.country}</Country>
          </Address>
        </Receiver>
        <TotalAmount currency="${values.currency}">${values.totalAmount}</TotalAmount>
        <InvoiceLines>
          ${values.invoiceLines.map(line => `
            <InvoiceLine>
              <ID>${line.id}</ID>
              <Description>${line.description}</Description>
              <Quantity>${line.quantity}</Quantity>
              <UnitPrice>${line.unitPrice}</UnitPrice>
            </InvoiceLine>
          `).join('')}
        </InvoiceLines>
      </Document>
    `;

    const blob = new Blob([xmlContent], { type: 'application/xml' });
    saveAs(blob, 'document.xml');
  };

  return (
    <div className="container mt-5">
      <div className="card shadow-sm p-4">
        <h3 className="text-center mb-4">Formulario Completo de Documento XML</h3>
        <Formik
          initialValues={formData}
          validationSchema={XMLFormSchema}
          enableReinitialize
          onSubmit={(values) => generateXML(values)}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <div className="form-group">
                <label>ID</label>
                <Field name="id" className="form-control" />
                <ErrorMessage name="id" component="div" className="text-danger" />
              </div>

              <div className="form-group">
                <label>Fecha de Emisión</label>
                <Field name="issueDate" className="form-control" />
                <ErrorMessage name="issueDate" component="div" className="text-danger" />
              </div>

              <div className="form-group">
                <label>Hora de Emisión</label>
                <Field name="issueTime" className="form-control" />
                <ErrorMessage name="issueTime" component="div" className="text-danger" />
              </div>

              <div className="form-group">
                <label>Tipo de Documento</label>
                <Field name="documentType" className="form-control" />
                <ErrorMessage name="documentType" component="div" className="text-danger" />
              </div>

              <h5>Datos del Remitente</h5>
              <div className="form-group">
                <label>Nombre del Remitente</label>
                <Field name="sender.name" className="form-control" />
                <ErrorMessage name="sender.name" component="div" className="text-danger" />
              </div>

              <div className="form-group">
                <label>ID del Remitente</label>
                <Field name="sender.id" className="form-control" />
                <ErrorMessage name="sender.id" component="div" className="text-danger" />
              </div>

              <div className="form-group">
                <label>Ciudad del Remitente</label>
                <Field name="sender.address.city" className="form-control" />
                <ErrorMessage name="sender.address.city" component="div" className="text-danger" />
              </div>

              <div className="form-group">
                <label>País del Remitente</label>
                <Field name="sender.address.country" className="form-control" />
                <ErrorMessage name="sender.address.country" component="div" className="text-danger" />
              </div>

              <h5>Datos del Receptor</h5>
              <div className="form-group">
                <label>Nombre del Receptor</label>
                <Field name="receiver.name" className="form-control" />
                <ErrorMessage name="receiver.name" component="div" className="text-danger" />
              </div>

              <div className="form-group">
                <label>ID del Receptor</label>
                <Field name="receiver.id" className="form-control" />
                <ErrorMessage name="receiver.id" component="div" className="text-danger" />
              </div>

              <div className="form-group">
                <label>Ciudad del Receptor</label>
                <Field name="receiver.address.city" className="form-control" />
                <ErrorMessage name="receiver.address.city" component="div" className="text-danger" />
              </div>

              <div className="form-group">
                <label>País del Receptor</label>
                <Field name="receiver.address.country" className="form-control" />
                <ErrorMessage name="receiver.address.country" component="div" className="text-danger" />
              </div>

              <div className="form-group">
                <label>Monto Total</label>
                <Field name="totalAmount" className="form-control" />
                <ErrorMessage name="totalAmount" component="div" className="text-danger" />
              </div>

              <div className="form-group">
                <label>Moneda</label>
                <Field name="currency" className="form-control" />
                <ErrorMessage name="currency" component="div" className="text-danger" />
              </div>

              <h5>Líneas de Factura</h5>
              <FieldArray name="invoiceLines">
                {({ push, remove }) => (
                  <>
                    {values.invoiceLines.map((line, index) => (
                      <div key={index} className="mb-3">
                        <div className="form-group">
                          <label>ID de Línea</label>
                          <Field name={`invoiceLines[${index}].id`} className="form-control" />
                          <ErrorMessage name={`invoiceLines[${index}].id`} component="div" className="text-danger" />
                        </div>
                        <div className="form-group">
                          <label>Descripción</label>
                          <Field name={`invoiceLines[${index}].description`} className="form-control" />
                          <ErrorMessage name={`invoiceLines[${index}].description`} component="div" className="text-danger" />
                        </div>
                        <div className="form-group">
                          <label>Cantidad</label>
                          <Field name={`invoiceLines[${index}].quantity`} className="form-control" />
                          <ErrorMessage name={`invoiceLines[${index}].quantity`} component="div" className="text-danger" />
                        </div>
                        <div className="form-group">
                          <label>Precio Unitario</label>
                          <Field name={`invoiceLines[${index}].unitPrice`} className="form-control" />
                          <ErrorMessage name={`invoiceLines[${index}].unitPrice`} component="div" className="text-danger" />
                        </div>
                        <button type="button" className="btn btn-danger" onClick={() => remove(index)}>Eliminar Línea</button>
                      </div>
                    ))}
                    <button type="button" className="btn btn-secondary mb-3" onClick={() => push({ id: '', description: '', quantity: '', unitPrice: '' })}>Agregar Línea</button>
                  </>
                )}
              </FieldArray>

              <button type="button" onClick={loadXMLData} className="btn btn-secondary mb-3">
                Cargar datos de prueba
              </button>
              <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                Generar XML
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
