import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AppRoute from './AppRoute';
// Views
import Dashboard from './Screens/Dashboard';
import NuevaHistoria from './Screens/Historias/nuevo';
import MainLayout from './Layout/MainLayout';
import VerHistoria from './Screens/Historias/ver';
import ListarHistorias from './Screens/Historias/listar';
import ListarPacientes from './Screens/Pacientes/listar';
import NuevoPaciente from './Screens/Pacientes/nuevo';
import VerPaciente from './Screens/Pacientes/ver';
import Login from './Screens/Login';
import AuthenticatedRoute from './AuthenticatedRoute';
import ImprimirHistoria from './Screens/Historias/imprimir';
import PrintLayout from './Layout/PrintLayout';
import pdfexample from './Screens/pdf';
import PdfExample from './Screens/pdf';
import Dpx from './Screens/Pacientes/dpx/';
import XMLForm from './Screens/Xml';

function App() {
  return (
    
    <Router>
        <Switch>
          <Route  path='/' exact component={Login} />
          <Route  path='/login' exact component={Login} />
          {/* Dashboard */}
          <AuthenticatedRoute  path='/dashboard' exact component={Dashboard} layout={MainLayout}/>
          {/* Pacientes */}
          <AuthenticatedRoute  path='/pacientes/listar' exact component={ListarPacientes} layout={MainLayout}/>
          <AuthenticatedRoute  path='/pacientes/nuevo' exact component={NuevoPaciente} layout={MainLayout}/>
          <AuthenticatedRoute  path='/pacientes/ver/:idPaciente' exact component={VerPaciente} layout={MainLayout}/>
          <AppRoute  path='/dpx/:id' exact component={Dpx} layout={MainLayout}/>
          {/* Historias */}
          <AuthenticatedRoute  path='/historias/listar' exact component={ListarHistorias} layout={MainLayout}/>
          <AuthenticatedRoute  path='/historias/nuevo' exact component={NuevaHistoria} layout={MainLayout}/>
          <AuthenticatedRoute  path='/historias/ver/:IdHistoria' exact component={VerHistoria} layout={MainLayout}/>
          <AuthenticatedRoute  path='/historias/imprimir/:IdHistoria' exact component={ImprimirHistoria} layout={PrintLayout}/>
          <AuthenticatedRoute  path='/pdf' exact component={PdfExample} layout={MainLayout}/>
          <AppRoute  path='/registro' exact component={NuevoPaciente} layout={MainLayout}/>
          <AppRoute path='/logout' exact layout={MainLayout} component={() => {localStorage.clear(); window.location.replace('/login')}} />
          {/* XML */}
          <AuthenticatedRoute  path='/xml' exact component={XMLForm} layout={MainLayout}/>
          <AppRoute  path='*' component={()=>('404')}/>
        </Switch>
      </Router>

  );
}

export default App;
