import React from 'react';
import axios from 'axios';
import Config from '../../Config';
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export default function Login() {

  const history = useHistory();
  const [submitting, setSubmitting] = React.useState(false);
  const [type, setType] = React.useState('password');

  React.useEffect(() => {
    if (localStorage.getItem('token') != null) {
      history.push('/dashboard');
    }
  }, [history]);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('El nombre de usuario es requerido'),
    password: Yup.string().required('La contraseña es requerida')
  });

  const handleSubmit = async (values, { resetForm }) => {
    setSubmitting(true);
    let params = {
      identifier: values.username.trim(),
      password: values.password.trim()
    };

    await axios.post(Config.url + 'auth/local', params)
      .then(response => {
        setSubmitting(false);
        localStorage.setItem('token', response.data.jwt);
        localStorage.setItem('username', response.data.user.username);
        history.push('/historias/nuevo');
      })
      .catch(error => {
        setSubmitting(false);
        resetForm();
        let errorDECODE = error.response?.data?.message[0]?.messages[0]?.message || 'Error desconocido';
        toast.error(errorDECODE);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid" style={{
        height: "100vh",
        background: "linear-gradient(135deg, #1e3c72 0%, #2a5298 100%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <div className="col-lg-5 col-md-7 col-sm-9 mx-auto">
          <div className="card shadow-sm p-4 rounded">
            <h3 className="text-center mb-4">Iniciar Sesión</h3>
            <Formik
              initialValues={{ username: '', password: '' }}
              validationSchema={LoginSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <Field
                      type="text"
                      name="username"
                      placeholder="Username"
                      className="form-control rounded-pill border-0 shadow-sm px-4"
                    />
                    <ErrorMessage name="username" component="div" className="text-danger mt-1" />
                  </div>
                  <div className="form-group mb-3">
                    <div className="input-group">
                      <Field
                        name="password"
                        type={type}
                        placeholder="Password"
                        className="form-control rounded-left border-0 shadow-sm px-4"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text rounded-right" onClick={() => setType(type === 'password' ? 'text' : 'password')}>
                          <i className={type === 'password' ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                      </div>
                    </div>
                    <ErrorMessage name="password" component="div" className="text-danger mt-1" />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting || submitting}
                    className="btn btn-primary btn-block rounded-pill shadow-sm text-uppercase"
                  >
                    {submitting ? 'Ingresando...' : 'Entrar'}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
